@import '../../main.scss';

// stylelint-disable no-descending-specificity
#RoundTwoSbaPppLoanApplicationForm {
  overflow-x: hidden;
  > .formContent {
    padding: 0px;
  }
}

.RoundTwoSbaPppLoanApplicationFormWrapper {
  overflow: auto;
  height: 100%;
  background-color: $Color__Fog;
  display: flex;
  flex-direction: column;

  .TileHelpBoxWrapper {
    display: flex;

    .RoundTwoHelpBox {
      margin-top: 32px;
      margin-left: -24px;

      position: sticky;
      top: 32px;
    }
  }

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 56px;
    width: 100%;
    padding: 0 16px;
    background-color: $Color__Marshmellow;
  }
  .Tiler {
    overflow-x: visible;
    .Tile {
      padding: 16px 21px 24px;
    }
  }
  .ui.divider.LSDivider {
    margin-bottom: 4px !important;
    border-top: none !important;
    border-bottom: 1px solid #a4b2bf !important;
  }
  .ui.divider.LSDivider + .lsHeader {
    margin-bottom: 16px;
  }

  .lsHeader.caps.steel {
    margin-bottom: 12px;
  }
  .SbaPppLoanApplicationForm {
    overflow-x: hidden;
    .IntroMessage.readOnlyText {
      margin-top: 16px !important;
      margin-bottom: -8px !important;
    }
    .OwnershipWrapper {
      display: flex;
      flex-direction: column;
      .LSDivider {
        margin-top: 0px !important;
      }
      .DeleteButton {
        align-self: flex-end;
        margin: 24px 24px 0 0;
        z-index: 1;
      }
      .DeleteButton + .RoundTwoOwnership {
        margin-top: -20px;
      }
    }
    .RoundTwoOwnership {
      > *:last-child {
        padding-bottom: 16px !important;
      }
    }
    .AddOwner {
      margin-top: -8px !important;
    }
    .RoundTwoFinancials > .LSTextLink.External {
      margin-bottom: 8px;
    }
    .RoundTwoFinancials > .LSTextLink.External > * {
      padding-top: 0px;
      padding-left: 0px;
    }
    .RoundTwoFinancials > .baseline {
      align-items: baseline;
    }

    .EndNotice {
      padding-top: 4px !important;
      padding-bottom: 24px !important;
    }
    .termsOfService {
      margin-top: 4px;
    }
    h5 {
      margin-bottom: 8px;
    }
  }

  .formFooter {
    display: flex;
    justify-content: flex-start;
    margin: 0px;
    .LSButton:first-child {
      margin-right: 8px;
    }

    .readOnlyText {
      margin-bottom: 0.75rem;
      margin-left: 24px;
      > .footerMessage {
        font-size: $FontSize__ParagraphLarge;
        width: 300px;
      }
    }
  }
}

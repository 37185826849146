@import '../../main.scss';

.TrancheTierForm {
  padding: 10px 0px 0px 0px;
  .TierForm-MoneyColumn {
    text-align: right;
    width: 115px;
    min-width: 115px;
  }
  .TierForm-TierNumberColumn {
    text-align: center;
    vertical-align: middle;
  }
}

@import '../../../main.scss';
@import './folderShareModal/FolderShareModal.scss';
@import './folderShareModal/shareModalContent/ShareModalContent.scss';
@import './folderShareModal/shareInstitutionItem/ShareInstitutionItem.scss';
@import './folderRowRenameInput/FolderRowRenameInput.scss';
@import './folderIcon/FolderIcon.scss';
@import './folderAccessPopup/FolderAccessPopup.scss';

.FolderRow {
  &__AccessCell {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__AccessLevel {
    color: $black;
    margin-left: 10px;
  }

  &__AccessAction {
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    display: flex;
    height: 100%;
    min-width: 100px;
  }

  &__Link {
    padding: 0.5714em 1.9285em;
    font-weight: 500;
    border-radius: 4px;
    border: none;
    color: $green700;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include noSelect();
    @include antialiasing();
    &.disabled {
      cursor: not-allowed !important;
      pointer-events: auto !important;
      opacity: 0.3;
    }
    &:not(.disabled) {
      @include clickAnimation();
      &:hover {
        color: $green700;
      }
      &:focus,
      &:active {
        color: $green900;
      }
      &.selected {
        color: $green900;
        &:hover,
        &:focus {
          color: $green700;
        }
      }
    }
  }

  &__FileCount {
    color: $gray500;
    margin-left: 2px;
  }
  &__NameCell,
  &-RenameInput,
  &__Progress {
    display: flex;
    align-items: center;
  }
  &__NameCell {
    cursor: pointer;
  }
  &__CheckIcon {
    margin: 4px;
  }

  &__Icon {
    font-size: 16px;
    align-self: center;
  }
  &__UploadInput {
    display: none;
  }
}

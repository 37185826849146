@import "../../main.scss";

.DataGrid {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin: 5px auto;
}
.DataGridHeader {
  display: table-header-group;
  font-weight: bold;
  height: 30px;
  border: none;
  .DataGridHeaderCell:last-child {
    border-right: none;
  }
}
.DataGridBody {
  display: table-row-group;
  .DataGridRow:last-child {
    .DataGridCell {
      padding-bottom: 5px;
    }
  }
}
.DataGridHeaderCell,
.DataGridCell,
.DataGridSummaryCell {
  > *:only-child:not(input):not(.input):not(.IconButton) {
    padding: 0 8px;
  }
}

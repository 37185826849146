@import '../../../main.scss';

.RoundTwoFinancials {
  .LoanAmount__Instructions {
    margin-top: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .LSTextLink.External .LSTextLink__Label {
    padding-left: 0px;
  }

  .field > .Checkbox.Checkbox__Container {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }

  .lsFormGroup.SchedC_InlineText {
    font-size: 13px;

    .field {
      padding-right: 8px !important;
    }

    .readOnlyText {
      margin-left: 0px;
      margin-top: 2px;
    }

    .textLine.requiredField:after {
      content: ' *';
      color: $Color__Rose;
    }
  }

  .Radio.Radio__Container {
    height: 20px;
  }
}

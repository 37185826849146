@import '../../../../main.scss';
.EzFormQualificationsModal {
  .Checkbox__Container {
    margin-top: 16px;
  }
  .QualificationsCheckboxText {
    white-space: pre-wrap;
    .And {
      text-align: center;
      margin: 8px 0;
    }
  }
}

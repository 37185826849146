@import '../../main.scss';
@import './calendarHeader/CalendarHeader.scss';
@import './calendarEventView/CalendarEventView.scss';
@import './calendarEventForms/CalendarTaskForm.scss';
@import './calendarEventForms/CalendarTaskFormSidebar/CalendarTaskFormSidebar.scss';
@import './calendarEventView/CalendarEventViewTable.scss';

#CalendarTable {
  height: initial;
  flex: 0.5 1 auto;
  th,
  td {
    &:first-child {
      width: 40px;
    }
    &:nth-child(2) {
      width: 100px;
    }
    &:last-child {
      width: 100px;
      text-align: center;
    }
  }
  tbody {
    td:last-child {
      overflow: initial;
    }
  }
}

.calendarFrame {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  background: $green50;

  &--CalendarView {
    padding-bottom: 33px;
  }

  &__EventCalendarView {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: 100%;
  }

  .calendarFrame__Content {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    overflow-x: auto;
  }

  .calendarFrame__eventFilter {
    width: auto;
  }
}

.FormDatePicker-CalendarView#CalendarView {
  max-width: 300px;
  max-height: 150px;
  .monthOuter {
    .daysOfTheWeek {
      .dayOfTheWeek {
        margin: 0;
      }
    }
    .week {
      .day {
        justify-content: center;
        align-items: center;
        &:not(.empty) {
          cursor: pointer;
        }
        &:hover:not(.empty),
        &:focus:not(.empty) {
          background-color: $impactGreen;
          .dayNumber {
            color: $white;
            font-weight: bold;
            @include antialiasing();
          }
        }
      }
    }
  }
}

.ActionButtons__Item--ListToggle {
  display: flex;
}

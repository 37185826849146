@import '../../../../main.scss';

.calendarTaskFormSidebar {
  width: 100%;
  height: 100%;
  &__Header {
    display: inline-block;
    color: $green700;
    font-weight: 700;
    border-bottom: 4px solid $green200;
    padding-bottom: 4px;
    margin-bottom: 18px;
  }
  &__FileSearchInput {
    padding: 0.67857143em 1em;
    width: 100%;
  }
  &__UploadButton.ui.button {
    margin-top: 27px;
  }
  &__FileIcon {
    font-size: 24px;
    @include setFileIconColors;
  }
  &__StagedFiles {
    padding: 0;
    margin: 23px 0 0 0;
  }
  &__StagedFile {
    list-style-type: none;
    margin: 17px 0;
    display: grid;
    grid-template-columns: 10% 83% 7%;
    grid-template-rows: 100%;
    > * {
      align-self: center;
    }
  }
  &__StagedFile:last-of-type {
    margin: 0;
  }
  &__StagedFileName {
    font-weight: 700;
  }
  &__RemoveFileButton {
    font-size: 13px;
  }
  &__SharedToggle {
    margin-bottom: 19px;
  }
}

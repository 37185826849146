.DataroomRowMenu {
  &--Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 4px 0px 4px;
    width: 40px;
    flex-shrink: 0;

    &__Action {
      cursor: pointer;
    }
  }
}

@import '../../../main.scss';
#institutionForm {
  .primaryAccountCheckbox {
    align-items: center;
  }

  .lsHeader {
    margin-bottom: 16px;
  }
  .lsFormGroup--Lowered {
    align-items: flex-end !important;
  }
  input::placeholder {
    color: $Color__Steel;
  }
}

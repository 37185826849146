@mixin clickAnimation($opacity: 0.6, $time: 50ms) {
  opacity: 1;
  transition: $time ease;
  &:hover,
  &:focus {
    opacity: $opacity;
  }
}

@mixin clickAndHoverAnimation($opacity: 0.5, $time: 50ms) {
  opacity: 1;
  transition: $time ease;
  &:hover,
  &:focus {
    transform: translateY(-1px);
    opacity: $opacity;
  }
  &:active,
  &.active {
    transform: translateY(2px);
  }
}

@mixin noSelect() {
  user-select: none;
}

@mixin antialiasing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin grabbing() {
  cursor: grabbing;
}

@mixin tableRowHover() {
  color: $black;
  svg {
    fill: $gray900;
  }
  /* stylelint-disable */
  transition: background-color 75ms ease;
  /* stylelint-enable */
  background-color: $green100;
}

@mixin contentCenterVertical {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

@mixin buttonStateColorStyling($hover, $active) {
  &:focus:not(.disabled) {
    /* stylelint-disable */
    transition: background-color 0.3s ease;
    /* stylelint-enable */
    background: $active !important;
  }
  &:active:not(.disabled) {
    /* stylelint-disable */
    transition: background-color 0.3s ease;
    /* stylelint-enable */
    background: $active !important;
  }
  &:hover:not(.disabled) {
    /* stylelint-disable */
    transition: background-color 0.3s ease;
    /* stylelint-enable */
    background: $hover !important;
  }
}

@mixin checkBoxStyling {
  label {
    font-weight: normal;
    padding-bottom: 0;
    &::before {
      width: 18px !important;
      height: 18px !important;
      border-color: $blueGrayShadow !important;
      border-width: 2px !important;
    }
    &:hover::before,
    &:focus,
    &:focus::before {
      border-color: $blueGrayShadow !important;
      border-width: 2px !important;
    }
    &:after {
      width: 18px !important;
      height: 18px !important;
      border-radius: 0.21428571rem;
      background: $primaryGreen !important;
      color: $white !important;
    }
  }

  &.checked {
    label {
      &::after {
        background: $primaryGreen !important;
        color: $white !important;
      }
    }
  }
}

@mixin setFont {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

@mixin setFormDisabledStyles {
  background: transparent !important;
  border: 1px solid transparent !important;
  text-align: left;
  resize: none !important;
  + .inputIcon svg {
    opacity: 1;
    color: $gray500;
  }
  ::placeholder {
    color: $gray500 !important;
  }
  > i.dropdown.icon {
    visibility: hidden;
  }
}

@mixin setFileIconColors {
  &--FilePdf {
    color: $pdfRed;
  }
  &--FileImage {
    color: $secondaryBlue;
  }
  &--FileWord {
    color: $wordBlue;
  }
  &--FilePowerpoint {
    color: $powerpointRed;
  }
  &--FileExcel {
    color: $excelGreen;
  }
  &--FileCsv {
    color: $gray600;
  }
  &--Folder {
    color: $gray600;
  }
  &--Clock {
    color: $primaryGreen;
  }
}

@mixin setInputHeightAndCenterVertically($height) {
  display: flex;
  align-items: center;
  min-height: $height;
  height: $height !important;
}

@mixin setDropdownHeight($height) {
  .ui.search.dropdown {
    @include setInputHeightAndCenterVertically($height);

    > i.dropdown.icon,
    div.visible.menu.transition > div.message,
    input.search,
    .menu > .item {
      @include setInputHeightAndCenterVertically($height);
    }
    .menu {
      .item {
        @include setInputHeightAndCenterVertically($height);
      }
    }
  }
}

@mixin setRadioStyles {
  label {
    color: $darkBlueGray !important;
    margin-left: 2.1px;
    &::before {
      border-radius: 100%;
      border-color: $gray500 !important;
      border-width: 1.5px !important;
      background-color: $white !important;
    }
    &:hover::before,
    &:focus::before {
      border-radius: 100%;
      border-color: $gray600 !important;
    }
    &::after {
      border-radius: 100%;
      border-color: $primaryGreen !important;
      background: $primaryGreen !important;
      color: $white !important;
      transform: scale(0.5) !important;
    }
  }

  &.checked label {
    &::before {
      height: 18px !important;
      width: 18px !important;
      border-radius: 100%;
      border-color: $primaryGreen !important;
    }
  }
}

@mixin setCommonInputStyles {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $gray600 !important;
  color: $darkBlueGray !important;
  font-size: 14px;

  outline: 0;
  outline-color: initial;
  outline-style: initial;
  outline-width: 0px;

  &::selection {
    background: $gray400;
  }

  &::placeholder {
    color: $gray300 !important;
  }

  &--Full {
    @include setInputHeightAndCenterVertically(30px);
    input {
      @include setInputHeightAndCenterVertically(30px);
    }
  }

  &--Slim {
    @include setInputHeightAndCenterVertically(26px);
    input {
      @include setInputHeightAndCenterVertically(26px);
    }
  }

  &:hover {
    box-shadow: 0 0 4px 0 $gray300 !important;
  }
  &:focus {
    border: 1px solid $primaryGreen !important;
  }
  &:disabled {
    @include setFormDisabledStyles;
  }
}

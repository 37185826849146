.shareModalContent {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0px 8px;

  &--input {
    margin: 0px 8px;
    width: 100%;
  }

  &--addButton {
    padding: 0px !important;
    margin: 0px 8px !important;
    display: flex;
    align-items: center;
    font-weight: 600;
    width: 98px;
    height: 30px;
  }
}

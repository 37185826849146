.DataGridCell.DataGridIconCell,
.DataGridHeaderCell.DataGridIconCell,
.DataGridSummaryCell.DataGridIconCell {
  width: 0%;
  width: 20px;
  min-width: 20px;
  vertical-align: middle;
  opacity: 1;
  &:not(.DataGridCell-DeleteColumn) {
    padding-left: 5px;
  }
  &-Hidden {
    opacity: 0;
  }
}
.DataGridHeaderCell.DataGridIconCell,
.DataGridSummaryCell.DataGridIconCell {
  a,
  button {
    cursor: initial !important;
    .lsSVG {
      visibility: hidden;
    }
  }
}

@import '../../../main.scss';

.invitationSplash {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('/img/images/inviteHero.jpg');
  background-size: cover;

  &__Hero {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  &__Gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      45deg,
      rgba(78, 175, 130, 0.8),
      rgba(52, 110, 83, 0.8)
    ) !important;
  }
  &__HeroText {
    margin-left: 26px;
    margin-bottom: 39px;
    position: absolute;
    left: 0;
    bottom: 0;
    color: $white;
    /* stylelint-disable */
    > div {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      margin: 0px 0px 0px 8px;
    }
  }
}

@import '../../../main.scss';

.BaseFields {
  &__DeleteTrancheButton {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 7px;
  }
  &__Header {
    font-weight: 400 !important;
  }
}

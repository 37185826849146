@import '../../../../../main.scss';

.BorrowerAdditionalInformationModal {
  .mediumPad {
    margin-top: 8px !important;
    border-bottom: 0px !important;
  }
  .lsFormGroup.AdditionalInfo {
    &__Lookup {
      align-items: flex-start;
      > .LSTextLink.External {
        margin-top: 20px;
      }
    }
    &__UseOfProceeds {
      align-items: baseline;
      .readOnlyText {
        margin-left: 8px;
        margin-top: 2px;
      }
      > .field .lsInput > .maskedInput {
        text-align: right;
      }
      > .disabledReadable {
        .lsInput--Disabled {
          border: none !important;
        }
        &.negative input.maskedInput {
          color: $Color__Rose !important;
        }
      }
    }
  }
}

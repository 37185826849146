@import '../../../../main.scss';

.ui.modal.LoanTrancheAmortization__Modal {
  /*
  We can beat Semantic's specifity by being a 
  little more precise with className, thus avoiding
  !important usage
  */
  width: 1000px;

  .content {
    padding: 20px;
  }

  .LoanTrancheAmortization__ResetButton {
    padding-right: 40px;
  }
}

.LoanTrancheAmortization {
  .LoanTrancheAmortization__CalculationStatus {
    padding: 8px 0px 8px 0px;
    height: 40px;
    .LoanTrancheAmortization__FormErrorList {
      padding: 0px;
    }
  }

  .AmortizationTable__Container {
    position: relative;
    width: 100%;
    padding: 0px;

    .AmortizationTable__DisplayTable {
      .DisplayTable-TableRow {
        min-height: 30px;
      }
      .DisplayTable-TableRowCell {
        padding: 0px 3px 0px 5px;
      }
      .PaymentRow__PrincipalPaymentInput {
        max-height: 26px;
      }

      .AmortizationTable__CountColumn {
        width: 45px;
      }

      .AmortizationTable__DateColumn {
        width: 100px;
      }

      .AmortizationTable__BeginningBalanceColumn,
      .AmortizationTable__InterestPaymentColumn,
      .AmortizationTable__PrincipalPaymentColumn,
      .AmortizationTable__EndingPrincipalColumn {
        width: 170px;
        padding: 0px 3px 0px 5px;
      }

      .AmortizationTable__ErrorColumn {
        width: 40px;
        color: $red;
      }
      .AmortizationTable__MoneyCell {
        float: right;
      }

      > thead {
        overflow-y: scroll;
      }

      tr {
        padding: 0px;
      }

      th {
        text-align: right;
        border: 0px;
      }
      > tbody {
        max-height: 360px;
        overflow-y: scroll;

        /*
          Without a min height the table height changes 
          between payment query runs, if a user makes a field change
          and clicks off the field, the modal form can shrink
          causing the user to click the dimmer and close the modal
        */
        min-height: 300px;

        td {
          text-align: right;
        }
      }
      > tfoot {
        overflow-y: scroll;
        tr {
          text-align: right;
          display: table;
          table-layout: fixed;
          width: 100%;
        }
        td {
          text-align: right;
          display: table-cell;
          font-size: 14px;

          overflow-x: hidden;
          white-space: nowrap;
          // padding: 2px 5px 2px 10px;
          vertical-align: top;
        }
        .AmortizationFooter__FirstRow {
          background-color: $green50;
          border-top: 1px #d8d8d8 !important;

          td {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

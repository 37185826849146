@import '../../../../main.scss';

.SbaHelpBox {
  height: 0px;
  width: 273px;
  margin-right: -8px;

  .Contents {
    height: max-content;

    border-top: 8px solid #fecd31;
    background-color: #fff5d6;
    padding: 12px;
  }

  &__Header {
    margin-bottom: 12px;
  }

  .spreadChildren {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__Section {
    margin-top: 12px;

    .SummaryField {
      margin-bottom: 4px;

      &__Label {
        margin: 0;
      }
    }
  }

  .LSButton.Text {
    display: inline;

    .LSButton__Label {
      color: $Color__LoanstreetGreen;
      padding: 0;
    }
  }
}

.calendarHeader {
  display: flex;
  padding: 19px 8px;
  justify-content: space-between;
  background: $white;
}

.calendarHeaderDateDisplay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &__Content {
    display: flex;
    align-items: center;
    color: $black;
    &:hover,
    &:focus {
      color: $gray600 !important;
    }
  }

  &__Button {
    width: auto !important;
    padding: 0px !important;
    background: transparent !important;

    &:focus > div > div {
      color: $gray600 !important;
    }
  }
  &__ButtonText {
    display: flex;
    align-items: center;
    font-size: 24px;

    &--Month {
      display: flex;
      font-weight: bold;
      margin-right: 8px;
    }
    &--Dash {
      margin: 0px 8px;
    }
    &--Year {
      font-weight: normal;
    }
  }
  &__Icon {
    color: $gray600;
    margin-left: 9px;
  }
}

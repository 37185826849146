.BorrowerApplication-PublicBanner {
  margin: 0px 0 0px;
  padding: 0 24px 0 0;
  background-color: #fff5d6;
  min-width: 100%;

  .BorrowerApplication-PublicBanner__Important {
    // height: 23px;
    margin: 12px 826px 2px 16px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b252e;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  .BorrowerApplication-PublicBanner__Detail {
    // height: 72px;
    margin: 2px 0 16px 16px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1b252e;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .LSTextLink__Label:hover,
    .LSTextLink__Label:focus {
      text-decoration: none;
    }
  }

  .Login__Button {
    margin-right: 16px;
    margin-left: 8px;
    height: 26px;
    .LSButton__Label {
      height: 17px;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }

  .BorrowerApplication-PublicBanner__Login {
    display: flex;
    align-items: center;

    margin-left: 16px;
    margin-bottom: 16px;



    .Modal__Button > .LSButton__Label {
      padding: 0 0 0 4px;
    }
  }
}

.SecondDrawModal {
  .readOnlyText {
    margin-bottom: 16px;
  }
}

.UserRoleTable {
  &__Row {
    width: 100%;
    display: grid;
  }
  &__Error {
    color: $red;
  }
  &__Text {
    margin: 0px 8px;
  }

  &__UserContainer {
    background: $green50;
    padding: 8px;
  }
}

.DealGenerator {
  &__QuickAdd {
    border: 3px $primaryGreen solid;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    &Header {
      color: $primaryGreen;
      margin-right: 1rem;
    }
  }
}

@import '../../../main.scss';

.RoundTwoOwnership {
  .field.zip-code {
    margin-left: 8px;
  }

  .voluntary-disclosure {
    font-weight: 600;
  }

  #businessTinTypeLabel:after {
    content: ' *';
    color: $Color__Rose;
  }
}

.DealForm-CreditRatings {
  margin-top: 20px;

  .lsFormGroup {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.industryCode {
  .ui.dropdown > .text,
  .ui.selection.dropdown .menu > .item {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .ui.selection.active.dropdown .menu {
    width: max-content;
    max-width: 520px;
  }
}

$minColumnWidth: 130px;
$columnPercentages: minmax($minColumnWidth, 22%) minmax($minColumnWidth, 17%)
  minmax($minColumnWidth, 17%) minmax($minColumnWidth, 17%)
  minmax($minColumnWidth, 17%) minmax($minColumnWidth, 10%);

.lenderRegistryTranche {
  margin-top: 24px;
  min-width: $minColumnWidth * 6;
  width: 100%;
  display: grid;
  grid-template: auto 1fr /100%;
  margin-bottom: 14px;

  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
  }
  &__Body {
    margin-top: 12px;
    box-shadow: 0 0 8px 0 $gray500;
    border-radius: 8px;
    background: $white;
    > div {
      padding: 15px;
    }
  }

  &__ColumnItem,
  &__MoneyDisplay {
    overflow-y: hidden;
    justify-self: end;
    padding-left: 4px;
    padding-right: 12px;
    &--LeftAlignText {
      justify-self: start;
    }
  }

  &__MoneyDisplay {
    margin-right: 10px;
  }
}

.lenderRegistryStatHeader {
  border-radius: 8px 8px 0px 0px;

  color: $gray300;
  background: $gray700;
  display: grid;
  grid-template: auto auto/ $columnPercentages;

  &__Category {
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    &Text {
      margin-right: 10px;
    }
  }
}

.lenderRegistryTrancheFooter {
  @include lenderRegistryGridRow();
  background: $gray500;
  color: $unallocatedTextColor;
  border-radius: 0px 0px 8px 8px;
  &__NameColumn {
    margin-left: 8px;
  }
  &__PercentColumn {
    > div > input {
      text-align: right !important;
      padding-right: 9px !important;
    }
    margin: 0px !important;

    &--Normal {
      > div > input {
        color: $unallocatedTextColor !important;
      }
    }

    &--OverAllocated {
      > div > input {
        color: $red !important;
      }
    }
  }

  &--OverAllocated {
    color: $red !important;
  }
}

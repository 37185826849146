@import '../../main.scss';

#PdfViewer {
  position: relative;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: stretch;
  @include transition(
    '.pdfUiFadein',
    (
      opacity: (
        0,
        1,
      ),
      transform: (
        translateY(20px),
        translateY(0),
      ),
      background-color: (
        lightgrey,
        $white,
      ),
      color: (
        $white,
        $black,
      ),
    ),
    300ms
  );
  i {
    // clickable controls
    margin: 0;
    opacity: 1;
    transition: 75ms ease;
    &:not(.disabled) {
      cursor: pointer;
    }
    &:hover:not(.disabled) {
      opacity: 0.65;
    }
    &:active:not(.disabled),
    &.active:not(.disabled) {
      transform: translateY(2px);
    }
  }
  .pdfViewerControlsTop {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
    .zoom,
    .rotate {
      background-color: $white;
      box-shadow: -1px 1px 2px $white;
    }
    .zoom:not(i) {
      padding: 15px 10px 5px;
      border-radius: 0 0 10px 10px;
      span {
        min-width: 5ch;
        display: inline-block;
      }
    }
    .selectOuter {
      display: flex;
      position: absolute;
      right: 90px;
      padding: 14px 10px 5px;
      border-radius: 0 0 10px 10px;
      background-color: $white;
      button {
        background: url('/img/icons/text-select-cursor.svg') center !important;
        background-position: center 2px !important;
        border: 1px solid $gray900;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: auto;
        transition: 75ms ease;
        &:active {
          transform: translateY(1px) !important;
        }
        &.selectable {
          background-color: rgba($black, 0.08) !important;
        }
      }
    }
    .rotate:not(i) {
      position: absolute;
      right: 22px;
      padding: 14px 10px 5px;
      border-radius: 0 0 10px 10px;
    }
    .rotate i:not(:last-child) {
      margin-right: 3px;
    }
  }
  .loader {
    position: relative;
    z-index: initial;
    top: initial;
    left: initial;
    margin: auto;
    transform: initial;
    height: 795px;
    &:before,
    &:after {
      top: calc(50% - 1.142857145rem);
    }
  }
  .PdfViewer_DragScroll {
    overflow: auto;
    padding: 50px 15px 80px;
    display: block;
    width: 100%;
    .react-pdf__Document {
      display: inline-block;
      padding-right: 15px;
      .react-pdf__Page {
        display: inline-block;
        canvas {
          margin: auto;
          &.react-pdf__Page__canvas {
            width: 100% !important;
            height: auto !important;
            box-shadow: -3px 5px 12px lightgrey;
            margin-bottom: 5px;
          }
        }
        .react-pdf__Page__textContent * {
          &::selection {
            background-color: $secondaryYellow;
          }
        }
      }
    }
    &.draggable .react-pdf__Page {
      cursor: pointer;
      &:active {
        @include grabbing();
      }
    }
  }
  .pdfViewerControlsBottom {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    margin-top: 10px;
    font-size: 18px;
    padding: 15px;
    border-radius: 10px;
    background: $white;
    z-index: 2;
    white-space: nowrap;
    span:not(.of),
    input {
      margin: 0 3px;
      text-align: center;
    }
    span:nth-child(2) {
      border-bottom: 1px solid $black;
      min-height: 22px;
      min-width: 20px;
      span {
        padding: 0 2px;
      }
      input {
        width: 50px;
        border: none;
        background: transparent;
        text-align: center;
      }
    }
    .of {
      margin: 0 5px;
      @include noSelect();
    }
  }
}

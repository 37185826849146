.fields.lsFormGroup.OtherBenchmarkOptionsFields {
  &__FormGroup {
    justify-content: space-between;
    width: 348px;
    .field.lsCheckbox {
      margin-top: 0.5em !important;
      margin-bottom: 0.5em !important;
    }
  }
}

@import '../../main.scss';

.invitationContentFrame {
  display: flex;
  height: 100%;
  &__ActionContent {
    width: 900px; //temp
    background: $white;
  }

  &__Description {
    margin-bottom: 12px;
  }

  &__LogoBackground {
    background-color: $primaryGreen;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    &--custom {
      background-color: #fff;
    }
    .logo-wrapper {
      height: 100%;
      padding: 3% 0;
      img {
        display: flex;
        height: 100%;
      }
    }
  }
  &__Form {
    padding: 16px;
    width: 100%;
    height: calc(100% - 70px);
    .TextButton {
      padding: 0 !important;
    }
  }

  &__InformationalContent {
    width: 100%;
    height: 100%;
  }

  &__ButtonContainer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

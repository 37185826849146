@import '../../main.scss';

$unallocatedTextColor: $gray400;

@mixin lenderRegistryGridRow() {
  display: grid;
  grid-template: auto/ $columnPercentages;
  > * {
    align-self: center;
  }
}

@import './LenderRegistryTranche/LenderRegistryTranche';
@import './LenderRegistryTranche/LenderRegistryInstitutionRow/LenderRegistryInstitutionRow';
@import './LenderRegistryTranche/LendingEntityGroup/LendingEntityRow';

.lenderRegistryForm {
  background: $white;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@import "../../main.scss";

.DataGrid {
  .DataGridCell,
  .DataGridHeaderRow,
  .DataGridSummaryRow {
    input.DataGridTextInput {
      width: 100%;
      text-align: right;
      background-color: $white !important;
      padding: 6px 8px 7px 17px;
      vertical-align: initial;
      &-HasIcon {
        padding-right: 23px !important;
      }
    }
  }
}

@import '../../../main.scss';

.HelpBox {
  height: 0px;
  width: 273px;
  margin-right: -8px;
  .Contents {
    height: max-content;
    background-color: $Color__RobinLight;
    padding: 12px;
    .Body {
      margin-top: 12px;
      .readOnlyText {
        margin-bottom: 16px;
      }
      .External .LSTextLink__Icon {
        padding-bottom: 0;
        color: $Color__LoanstreetGreen;
      }
    }
  }
}

@import '../../../main.scss';

#Main .AllLoans {
  .lsDataTable {
    > .Header,
    .Body .Row,
    .Footer {
      //                     STR, CRTD, BRWR,  ID,  DRAW, ASGN, STG, STAT, SIZE, VIEW
      grid-template-columns: 30px 150px 205px 100px 115px 125px 80px 140px 125px 50px;
    }
  }
}

@import '../../main.scss';

.NotFound {
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    &__Header {
      margin-top: 4px;
    }

    &__Message {
      width: 400px;
      margin-top: 16px;
      margin-bottom: 25px;
    }

    .LSButton {
      align-self: flex-end;
    }
  }
}

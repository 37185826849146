.adjustableRatePeriodTable {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  &__Row {
    height: 26px;
    display: grid;
    grid-template-columns: 22% 25% 26% 27%;
    grid-template-rows: 100%;
    margin-bottom: 5px;
  }

  &__Header {
    font-weight: bold;
  }

  &__Column {
    text-align: right;
    padding-left: 6px;
    padding-right: 6px;
    align-self: center;

    input.adjustableRatePeriodTable__ColumnContent {
      text-align: right;
    }
  }

  &__TotalColumn {
    text-align: center;
  }
}

.InterestRateResetRulesRow {
  .checkbox {
    margin-top: 30px;
  }
}

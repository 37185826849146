@import '../../main.scss';
@import './PaymentFilterHeader/PaymentFilterHeader.scss';

.paymentsView {
  height: 100%;
  width: 100%;
  background: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.DisplayTable-TableBody {
  height: calc(100% + 181px);
}

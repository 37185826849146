.shareInstitutionItem {
  width: 100%;
  height: 40px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &--text {
    font-weight: 500px;
    min-width: 280px;
  }

  &--dropDown {
    color: $gray600;
    background: $white;
    height: 30px;
    min-width: 180px !important;
  }
}

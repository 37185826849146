@import '../../../main.scss';

.RoundTwoInstitution {
  .field.zip-code {
    margin-left: 8px;
  }
  .LSTextLink {
    margin-top: 20px;
  }

  #businessTinTypeLabel:after {
    content: ' *';
    color: $Color__Rose;
  }
}

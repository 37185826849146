@import "../../main.scss";

.DataGridHeaderCell {
  display: table-cell;
  padding: 1px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border: none;
  color: $darkBlueGray;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
}

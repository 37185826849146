@import '../../../main.scss';

.NoAccess {
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;

  .header {
    color: $darkBlueGray !important;
  }
}

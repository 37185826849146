@import '../../../main.scss';

.TrancheRow--FormSections {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.TrancheRow--FormSection--Content {
  min-width: 415px;
  max-width: 415px;
  margin-right: 70px;
  display: flex;
  flex-wrap: nowrap;
}

@import '../../main.scss';

.GeneralNoticeForm {
  &__Container {
    width: 415px;
  }
}

.GeneralNoticeSummary {
  &__Container {
    padding: 16px 16px 14px 16px;
    width: 415px;
  }

  &__Header.ui.header {
    margin-top: -0.14285714em;
  }

  &__TrancheNameDivider {
    font-size: 13px !important;
    margin-top: 18px !important;
  }

  &__Note > label {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $darkBlueGray !important;
  }
}

@import '../../main.scss';

.CreditRatings-Form-DeleteButton {
  margin-top: 1.5em;
  svg {
    color: $gray600;
  }

  &--Fixed {
    position: absolute;
    top: 35px;
    right: -25px;
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    svg {
      color: $gray600;
    }
  }
}

.lendingEntityRow {
  background: $white;
  color: $black;
  display: grid;
  @include lenderRegistryGridRow();
  &__NameColumn {
    margin-left: 50px;
  }
}

.lenderRegistryInstitutionFooter {
  @include lenderRegistryGridRow();
  background: $white;
  color: $unallocatedTextColor;
  &__NameColumn {
    margin-left: 50px;
  }
}

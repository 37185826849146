.FeeModal .ModalBody {
  min-height: 350px;
  .lsDropdown.active.visible.search.selection .visible.menu.transition {
    max-height: 200px;
  }
}
.FeeModalForm {
  &__TrancheLabel {
    color: $gray500;
    font-size: 13px;
    margin-bottom: 16px;
  }

  &__Group {
    &--PaddedRight {
      padding-right: 32px;
    }
  }

  &__AmountPercentageContent {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 100%;
  }

  &__AmountPercentage {
    grid-column: 1/17;
    &--Half {
      grid-column: 1/9;
      padding-right: 7px;
    }
  }
  &__Footer {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__ButtonGroup {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  &__SubmitButton {
    margin-left: 13px !important;
  }
}

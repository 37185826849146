@import '../../../../main.scss';

.Section {
  padding-bottom: 30px;
  background-color: $white;
  &-Table {
    background-color: $white;
    height: 100%;
  }
  &-TableHeader {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  &-Actions {
    width: 85px;
    text-align: center;
    padding-left: 5px;
  }

  &-SectionRow {
    background-color: $white !important;
    &-Contact {
      padding-top: 22px;
      padding-bottom: 29px;
    }
    &-Name {
      padding-right: 8px;
    }
    &--EditButton {
      margin-left: 16px;
    }
  }

  &-UserRow {
    &-Even {
      background-color: $white !important;
    }
    &-Odd {
      background-color: $gray200 !important;
    }
    &-Contact {
      overflow: hidden;
      font-weight: 800;
      &--Info {
        display: block;
        font-style: italic;
        font-size: 11px;
        font-weight: normal;
      }
      &--ResendButton {
        margin: 0 8px;
        font-weight: 600;
      }
    }
    &-DealAccess {
      /* overflow visible allows the Dropdown to be 
        visible within the row, otherwise the panel is 
        hidden inside the cell element
      */
      overflow: visible;
    }
    &-Actions {
      width: 85px;
      text-align: center;
      padding-left: 5px;
    }

    .DisplayTable-TableRowCell {
      padding: 9px 15px;
      vertical-align: top;
    }
  }
}

@import '../../main.scss';
@import './dataroomHeader/DataroomHeader.scss';

#Dataroom {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  background: $white;
}

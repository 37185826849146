@import '../dealClosingWorkflow/DealClosingWorkflow.scss';

.RoundTwoSecondDrawLenderApplication {
  // SBA-Hack
  overflow-y: hidden;

  .section {
    .field .ui.text .readOnlyText {
      margin-bottom: 4px !important;
    }
    .field {
      margin: 15px 0 0 0 !important;
      margin-top: 7px !important;
    }
    .FormField__SmallText--Help {
      margin-top: 0px !important;
    }
    .section__help-text {
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.38;
      letter-spacing: normal;
    }
    .Radio.Radio__Container {
      max-height: 25px;
    }
  }
}

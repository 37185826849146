.ApplicableMargins {
  margin-left: 24px !important;
  justify-content: space-between;
  width: 249px;
  .field {
    margin-bottom: 0 !important;
  }
  &__AddLevelButton {
    padding-left: 0 !important;
  }
}

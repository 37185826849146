@import '../../../main.scss';

.Summary {
  width: 100%;
  max-height: 100%;
  height: 100%;
  align-self: stretch;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  .TabNavigationMenu {
    margin: 8px 0 16px 24px;
  }
  .FacilitySection {
    .SummaryField {
      width: 200px;
      &:not(:last-child) {
        margin-right: 56px;
      }
    }
  }
}

@import '../../main.scss';

#DealContext {
  display: flex;
  height: calc(100vh - 56px);
  color: $darkBlueGray;

  .DealContext-Body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

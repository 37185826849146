@import '../../../../main.scss';

.CollateralsRow {
  position: relative;
}

.CollateralFields-DeleteButton {
  position: absolute;
  top: 34px;
  right: -30px;
}

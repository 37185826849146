@import './scss/resets.scss';
@import './main.scss';

body {
  @include setFont;
  margin: 0 0 0 0;
  height: 100vh !important;
  overflow-y: hidden !important;
  -moz-osx-font-smoothing: initial !important;
  -webkit-font-smoothing: initial !important;
  /* stylelint-disable-next-line */
  font-smoothing: initial !important;
  color: #0c0000 !important;
  .tabNavigationFrame__Content {
    background: unset;
  }
}

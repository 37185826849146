@import '../../../main.scss';
@import './BorrowerPaymentView/BorrowerPaymentView.scss';
@import './LenderPaymentView/LenderPaymentView.scss';

.paymentRecord {
  &__Form {
    width: 415px;
  }

  &__AmountInput {
    > input {
      text-align: left;
    }
  }

  &__Label {
    font-size: 14px;
    color: $darkBlueGray;
    font-weight: bold;
    margin-top: 8px;
  }

  &__InstructionText {
    margin: 10px 0px;
  }

  &__Divider {
    width: 100%;
    padding-bottom: 9.5px;
  }
}

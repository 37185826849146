.calendarTaskForm {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 100%;

  &__Main {
    padding-right: 35px;
  }
  &__FormGroup--Thirds {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

.calendarTaskFrequencyBody {
  &__Label {
    color: $darkBlueGray;
    font-weight: bold;
    padding-bottom: 0.28571429rem;
    @include setFont;
  }

  &__Group {
    display: flex;
    align-content: center;
  }
}

.ui.modal.calendarTaskModal {
  width: 1000px;
}

@import '../../../main.scss';

.OrderBooksForm {
  display: grid;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  > div {
    padding: 11px 16px;
  }
  .OrderBooksForm-Tiers {
    background-color: $white;
    h2 {
      margin-bottom: 0px;
      font-weight: initial;
    }
    .OrderBooksForm-Tiers-Forms {
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      max-width: 100%;
      // overflow-x: auto;
    }
  }
  .OrderBooksForm-Tranches {
    background-color: $white;
    border-collapse: collapse;
    width: 908px;
    max-width: 100%;
    h2 {
      font-weight: initial;
    }
  }
}

@import '../../main.scss';

/* stylelint-disable no-descending-specificity */
.SbaPppForgivenessWorkflow__Wrapper {
  color: $Color__Steel;

  .WorkflowSection {
    margin-top: 16px;

    &--Items > * {
      margin-top: 16px;
      margin-bottom: 0px;
    }
    &--Items label {
      padding-bottom: 0px;
    }
  }

  .Tile__Header {
    width: 100%;
    justify-content: space-between;
    .SecondarySection {
      width: 273px;
      margin: 0;
    }
  }

  .Tile__Footer {
    margin-top: 16px;
  }

  .ExcelLink {
    &.withIcon {
      display: flex;
      margin-bottom: 12px;
    }
    .Icon {
      margin-right: 6px;
      color: $Color__LoanstreetGreen;
    }
  }

  .CtaButtons {
    width: 100%;

    .CtaButtons__Wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
      }

      button ~ button {
        margin-left: 16px;
      }
    }
  }

  .OrderedList {
    padding-left: 30px;

    li {
      margin-top: 12px;
      padding-left: 6px;
    }
  }

  .FileUploader {
    &__Label {
      color: $Color__LoanstreetGreen;
      cursor: pointer;
    }
    &__Input {
      // a11y issues with `display: none`/`visibility: hidden`
      display: none;
    }
  }

  .ConstrictedContent {
    width: 544px;
  }

  .TosCheckboxButton {
    display: inline;

    .LSButton__Label {
      color: $Color__LoanstreetGreen;
      padding: 0;
    }
  }

  .ForgivenessErrors {
    .ForgivenessErrors__Error {
      margin: 8px 0px;
      font-weight: bold;

      span {
        color: $Color__Rose;
        font-weight: normal;
      }
    }
  }
}

.CheckboxForm {
  .field {
    .Checkbox {
      padding-bottom: 0 !important;
    }
  }
}

@import '../../../main.scss';

.FileRow {
  &-NameCell,
  &-RenameInput {
    display: flex;
    align-items: center;
    &__Uploading {
      opacity: 0.3;
    }

    > a {
      display: inline-flex;
      align-items: center;
    }

    &-FileIcon {
      margin-right: 4px;
      font-size: 20px;
      @include setFileIconColors;
    }
  }
  &__AccessCell {
    @include contentCenterVertical;
    justify-content: space-between;
  }

  &__Icon {
    align-self: center;
    font-size: 16px;
    &--Check {
      margin-left: 4px;
    }
  }

  &--AccessContainer {
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 100%;
    min-width: 100px;
  }
}

@import '../../../main.scss';

.ForgivenessCalculationForm {
  .CheckQualificationsButton .LSButton__Label {
    margin: 0px !important;
    padding: 0px !important;
  }
  .FormController {
    width: 544px;
    display: flex;
    justify-content: space-between;
    .LSButton__Label {
      margin: 0px !important;
      padding: 0px !important;
    }
  }
  .Tooltip {
    margin-left: 8px;
  }
  .readOnlyText > .ForgivenessIsSmallest {
    margin-bottom: 16px !important;
  }
  .lsFormGroup {
    &.Field-Tooltip {
      margin-top: 16px !important;
      .Tooltip {
        margin-top: 24px;
      }
      &.ForgivenessAmountSubValue {
        align-items: baseline !important;
        margin-top: 0 !important;
        .readOnlyText {
          margin-left: 8px;
          color: $Color__Robin;
        }
      }
    }
    &.Checkbox-Tooltip {
      margin-bottom: -16px !important;
      .Tooltip {
        margin-top: 4px;
      }
    }
  }
  .LessThanFullForgivenessCauses .ReasonList {
    padding-inline-start: 20px;
  }
  &.SForm .Tile__Body {
    min-height: 260px;
    > .HideCalculation {
      margin-top: 0px;
      .LSDivider {
        margin: 0px !important;
      }
      .HelpMe {
        margin-top: 20px;
      }
    }
  }
}

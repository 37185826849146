@import '../../main.scss';

.UploadDropzone {
  width: 100%;
  text-align: center;
  &__Dropzone {
    display: inline-block;
    border-color: $gray300;
    border-style: dashed;
    padding: 20px 5vw;
    margin: 15px 0;
    border-radius: 20px;
    font-size: 19px;
    border-width: 4px;
    width: 100%;

    cursor: pointer;
    white-space: nowrap;
    text-align: center;

    &.accept {
      cursor: copy;
      border-color: $impactGreen;
      transform: scale(1.04);
    }
    &.reject {
      // cursor: not-allowed;
      border-color: $red;
      transform: scale(0.95);
    }
    > *:not(:last-child) {
      margin-bottom: 10px;
    }

    @include transition(
      '.error',
      (
        opacity: (
          0,
          1,
        ),
      ),
      300ms
    );
    .error {
      font-size: 14px;
      color: $red;
    }
  }

  &__StagedFiles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 12px 10px 12px;
  }
}

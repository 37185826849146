@import '../../main.scss';

.AttachedFiles {
  margin-bottom: 12px;

  .ui.header:first-child {
    margin: calc(2rem - 0.14285714em) 0 1rem;
  }

  &__Icon {
    @include setFileIconColors;
  }

  &__FileRow {
    display: flex;
    align-items: center;
    line-height: 1.7;
    svg {
      font-size: 20px;
    }
    &__Delete {
      padding-left: 15px;
    }
  }

  &__FileName {
    padding: 0 0 0 5px !important;
  }
}

@import '../../../../main.scss';

.GuarantorsRow {
  position: relative;
  .lsFormGroup {
    align-items: flex-end !important;
    .GuarantorFields-DeleteButton {
      margin-bottom: 0.75em;
      svg {
        color: $gray600;
        margin: 4px 0 0 14px;
      }
    }
  }
}

@import '../../main.scss';
$trancheLaneWidth: 296px;
$tierWidth: 54px;
@import './feeItem/FeeItem.scss';
@import './feeModalForm/FeeModalForm.scss';
@import './feeAmountPercentageGrid/FeeAmountPercentageGrid.scss';
@import './feePrepayment/FeePrepayment.scss';

.feesGridForm {
  height: 100%;
  overflow-x: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  padding-top: 16px;

  &__Tiers {
    background: $white;
  }

  &__Fees {
    width: 100%;
    overflow-y: auto;
    background: $white;
  }

  &__Header {
    margin: 8px 16px 0px 16px !important;
    &--Sub {
      margin: 2px 16px 14px 16px !important;
      font-size: 14px;
    }
  }

  &__Content {
    display: flex;
    &--Tiers {
      padding-right: 16px;
    }
  }

  &__TrancheHeader {
    margin: 4px 0px 5px 0px !important;
  }

  &__TrancheLane {
    margin-left: 16px;
    height: 100%;
    min-width: $trancheLaneWidth;

    &:last-child {
      margin-right: 16px;
    }
  }

  &__AddFeeButton {
    margin-bottom: 50px;
  }
}

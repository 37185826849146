.feeItem {
  width: $trancheLaneWidth;
  padding-top: 2px;
  border-top: 1px solid $Color__RobinLight1;

  &__Description {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 9px 0px 11px 0px;
  }
  .FormSection__Header {
    .FormSection__CollapseIcon {
      margin-top: 8px;
      color: $Color__Steel !important;
      > .Icon {
        font-size: 8px !important;
      }
    }
  }
  &__EditIcon {
    &.LSButton.IconButton {
      font-size: 8px;
      color: $Color__Steel !important;
    }
  }

  &__Input {
    margin-left: 54px !important;
    > label {
      text-align: right;
    }
    > .ui.input {
      > input {
        text-align: right;
      }
    }
  }

  &__Display {
    > label {
      text-align: right;
    }
    > div {
      text-align: right;
    }
  }
}

@import '../../../main.scss';

#AcceptInviteForm {
  height: 100%;
  overflow: auto;
  iframe {
    width: 100%;
    height: calc((6 * 69px) + 30px + 96px);
    /**
     * height is somewhat hard-coded; adjust if modifying iFrame contents
     * 6 fields, 69px each; 30px button; 96px extra space for error messages
     */
    border: none;
    margin-top: calc(2rem - .14285714em);
  }
  &__Description {
    margin-bottom: 12px;
  }

  &__ButtonContainer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .wide.field {
    padding-left: 0;
  }
}

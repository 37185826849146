@import "../../main.scss";

.DataGrid {
  .DataGridCell,
  .DataGridHeaderRow,
  .DataGridSummaryRow {
    input.DataGridPercentageInput {
      width: 100%;
      background-color: $white !important;
      padding: 6px 8px 7px 17px;
      vertical-align: initial;
    }
  }
}

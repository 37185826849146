@import '../../main.scss';
@import 'sections/interestForm/AdjustableRateFields.scss';

.SectionedForm-Rows {
  width: fit-content;
  min-width: 100%;
  padding-right: 16px;
}

#loanTranches {
  .Tiler > .Tile.Full {
    max-width: max-content !important;
    min-width: auto !important;
    .FormSection__Header {
      .FormSection__CollapseIcon {
        margin-top: 8px;
        color: $Color__Steel !important;
        > .Icon {
          font-size: 8px !important;
        }
      }
    }
  }
}

@mixin headerProps {
  color: $Color__Steel;
  font-weight: normal;
  font-size: 11px;
}
.feeAmountPercentageGrid {
  &__ColumnHeader {
    &--Tier {
      @include headerProps;
      text-align: center;
      width: $tierWidth;
      min-width: $tierWidth;
    }
    &--Modal {
      @include headerProps;
      text-align: left;
    }
    &--Amount {
      @include headerProps;
      padding-right: 7px;
    }
    &--Percentage {
      @include headerProps;
      padding-left: 7px;
    }
  }

  &__Tiers {
    font-size: 14px;
    width: 26px;
    margin-right: 28px;
    height: 100%;
  }
  &__Readonly {
    text-align: right !important;
    display: block;
  }
}

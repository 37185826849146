#UserProfileView {
  .Contact {
    &__FullName {
      font-weight: bold;
    }
  }
  .lsFormGroup {
    .FormReadOnly {
      margin-right: 24px;
    }
  }
}

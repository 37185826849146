@import '../../main.scss';
/* stylelint-disable no-descending-specificity */
.DealClosingWorkflow__Wrapper {
  .LSDivider {
    &.mediumPad {
      margin-top: 16px !important;
      border-bottom: 0px !important;
    }
    &.largePad {
      margin-top: 24px !important;
      border-bottom: 0px !important;
    }
  }

  .GenericWorkflowHeader {
    .Group {
      display: flex;
      align-items: baseline;
      margin-bottom: 24px;
      &.justified {
        justify-content: space-between;
      }
    }
    .readOnlyText {
      margin-top: 0px !important;
    }
    .escapeHatch {
      min-width: 0px !important;
      .LSButton__Label {
        padding: 0px !important;
      }
    }
  }

  .DealClosingWorkflow .DealClosingWorkflowStep {
    margin: 14px 0 18px;
    &.isDimmed {
      color: $Color__Wall !important;
      > .lsHeader,
      .readOnlyText > .textLine {
        color: $Color__Wall !important;
      }
    }

    .StepCheckbox {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .field {
      margin-top: 0 !important;
      .Checkbox {
        padding-bottom: 0 !important;
      }
    }
    .Group {
      display: flex;
      align-items: baseline;
      font-size: 13px;
      .LSTextLink.External > .LSTextLink__Label {
        padding-bottom: 0px;
      }
      .LSTextLink:hover > .LSTextLink__Label,
      .LSTextLink:focus > .LSTextLink__Label {
        text-decoration: none;
      }
      &.justified {
        justify-content: space-between;
      }
      &.left {
        justify-content: flex-start;
      }
      &.padded4 > * {
        margin-left: 8px;
      }
      &.padded3 > * {
        margin: 12px 0px 8px;
      }
      &.padded2 > * {
        margin: 16px 8px 0px;
      }
      &.padded > * {
        margin: 16px 8px;
      }
      &.padded2 > * :first-child,
      &.padded > * :first-child {
        margin-left: 0px;
      }

      .bold > .unbold {
        font-weight: normal;
      }
      > .noMinWidth {
        min-width: 0px !important;
      }
    }
    .LenderLoanApplication {
      .loanDisplay {
        > .justified {
          margin-top: -32px;
        }
        > .left {
          margin-top: 16px;
          width: 320px;
          justify-content: space-between;
        }
      }
      > .EtranReadyCheck {
        margin-bottom: 16px;

        .LSButton.Text {
          min-width: 0px !important;
          margin-top: 0px !important;
        }
        .readOnlyText {
          margin-top: 4px !important;
        }
        .Group.checkboxes {
          align-items: flex-end;
          > .Icon {
            max-width: 12px !important;
            margin: 2px 8px 2px 4px;
            &.fa-times {
              color: $Color__Rose !important;
              max-width: 8px !important;
            }
          }
        }
        .reasons {
          color: $Color__Rose;
          margin-left: 36px;
        }
      }

      > .LenderRejected {
        > .readOnlyText {
          margin-top: 16px !important;
        }
        > .reasons {
          color: $Color__Rose;
          margin-top: 8px;
          margin-bottom: 16px;

          > p {
            margin-bottom: 8px;
          }
        }
      }
    }
    .ManualApproval > .ModalBody {
      margin-right: -4px;
      .RecordSbaApproval {
        margin-top: -16px;
        .lsFormGroup {
          width: 280px;
          align-items: flex-start;
        }
      }
    }

    .LSButton.Text.label {
      .LSButton__Label {
        font-weight: $FontWeight__Normal;
        font-size: 13px;
        padding: 0;
      }
    }
    .ModalFooter > button {
      margin-left: 8px;
    }
  }
  > :nth-child(2) {
    margin-top: 0 !important;
  }
  > :last-child {
    margin-bottom: 0 !important;
  }
  ul {
    margin-top: -14px;
    li {
      color: #747574 !important;
    }
  }

  .downloadLinkContainer {
    display: flex;
    align-items: center;
    margin: 11px 0 18px 0;
    .downloadLink {
      margin-left: 7px;
    }
  }

  .readOnlyText {
    margin-top: 18px !important;
    p.textLine,
    li.textLine {
      font-size: 13px;
      &.questions3and4 {
        margin-bottom: -4px !important;
        margin-top: 19px !important;
      }
      &.AdditionalInfo__Franchise {
        margin-bottom: 8px !important;
      }
      &.originate {
        margin-top: -16px !important;
      }
      &.ownership {
        margin-bottom: 16px !important;
      }
    }
  }

  .ui.divider.LSDivider {
    margin-bottom: 4px !important;
    border-top: none !important;
    border-bottom: 1px solid #a4b2bf !important;
  }
  .ui.divider.LSDivider + .lsHeader {
    margin-bottom: 19px;
  }

  &__ActionButtons {
    display: flex;
  }
  button.LSButton.submit {
    margin-top: 40px;
  }

  .FileUploader {
    &__Label {
      color: $Color__LoanstreetGreen;
      cursor: pointer;
    }
    &__Input {
      // a11y issues with `display: none`/`visibility: hidden`
      display: none;
    }
  }
}
.LenderRequestResubmissionModal > .ModalBody {
  .readOnlyText > .ui.text > .readOnlyText {
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }
  .field {
    margin-bottom: 0px;
    margin-top: 0 !important;
    .Checkbox {
      padding-bottom: 0 !important;
    }
  }
  .LSTextArea {
    max-height: 560px;
  }
}

.FolderIcon {
  display: flex;
  margin-right: 4px;
  font-size: 14px;
  .fa-caret-right,
  .fa-caret-down {
    font-size: 12px;
    top: 5px;
    right: 1px;
    bottom: unset;
    left: unset;
  }
}

@import '../../../../main.scss';
.DemographicInformationModal {
  .ModalBody {
    height: 100%;
    .Owner {
      margin-top: 16px;
      &:last-child {
        margin-bottom: 104px;
      }
    }
  }
}

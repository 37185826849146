@import '../../../../main.scss';

.OtherCovenantItem {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  &__DeleteButton {
    margin-bottom: 0.5em;
    svg {
      color: $gray600;
      margin: 4px 0 0 14px;
    }
  }
}

@import '../../main.scss';
.HelpView {
  hr {
    margin: 16px 0px;
  }

  p.textLine {
    margin-top: 12px !important;
  }

  .HelpView__SpecialIconButton {
    display: flex;
    align-items: center;
    margin: 8px 0px 8px 8px;

    .Icon {
      margin-right: 4px;
    }
    .LSTextLink__Label:hover,
    .LSTextLink__Label:focus {
      text-decoration: none;
    }
  }
}

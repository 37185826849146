@import '../../../main.scss';

.WorkflowItem {
  display: flex;
  justify-content: space-between;

  &__Label {
    display: flex;

    .Tooltip {
      margin-left: 4px;
    }
  }
}

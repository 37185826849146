.dataroomHeader {
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  grid-template-rows: 22px;
  padding-right: 0.7rem;
  background: $gray900;
  box-sizing: border-box;
  height: 1.4285714286rem;

  &__Icon {
    margin-left: 4px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  }

  /* stylelint-disable a11y/line-height-is-vertical-rhythmed */
  &__Column {
    padding-left: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;
    font-size: 0.8571428571rem;
    border-right: 1px solid $gray600;
    height: 1.4285714286rem;
    line-height: 1;
    /* stylelint-enable a11y/line-height-is-vertical-rhythmed */

    &:last-child {
      border-right: none;
    }

    &--Selected {
      background: $gray900;
    }

    &--NotSelected {
      background: $gray900;
    }
  }
}

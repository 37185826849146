@import '../../../main.scss';

.RoundTwoHelpBox {
  height: 0px;
  width: 273px;
  color: $Color__Steel;

  .Contents {
    // height: max-content;
    background-color: $Color__RobinLight;
    padding: 12px;
    .Body {
      margin-top: 12px;
      .readOnlyText {
        margin-top: 16px;
        margin-bottom: 8px;
      }
      .LSTextLink__Label {
        padding-left: 0px;
      }
      .LSTextLink__Label:hover,
      .LSTextLink__Label:focus {
        text-decoration: none;
      }

      .Icon {
        color: $Color__Ivy;
      }

      .RoundTwoHelpBox__SpecialIconButton {
        display: flex;
        align-items: center;
        margin: 8px 0px 8px 8px;

        .Icon {
          margin-right: 4px;
        }
        .LSTextLink__Label:hover,
        .LSTextLink__Label:focus {
          text-decoration: none;
        }
      }
    }
  }
}

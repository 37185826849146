@import '../../../main.scss';

.OrderBooksForm-Tranches {
  .TrancheOrderBook {
    padding-top: 6px;
    .FormSection-Header {
      margin-bottom: 0;
    }
    .TrancheOrderBook-Header {
      display: flex;
      align-items: center;
      .TrancheOrderBook-Header-FormHeader {
        padding: 0 10px 0 8px;
        margin-bottom: 0;
        color: $black !important;
      }
      .TrancheOrderBook-Header-Detail {
        font-size: 12px;
        font-weight: initial;
        opacity: 0.8;
      }
    }
  }
  .OrderBooksForm-Tiers-FixedColumn {
    $inputColumnWidth: 165px;
    width: $inputColumnWidth;
    min-width: $inputColumnWidth;
    text-align: right;
  }
  .OrderBooksForm-Tiers-InvestorNameColumn {
    width: 100%;
    min-width: 165px;
    padding-left: 50px;
    font-weight: bold;
    text-align: left;
  }
  .OrderBooksForm-Tiers-TierColumn {
    min-width: 56px;
    width: 8%;
    &.DataGridHeaderCell {
      text-align: center;
    }
    &.DataGridCell {
      text-align: right;
    }
  }
  .OrderBooksForm-Tiers-MidColumn .Money {
    padding: 0 8px;
  }
  .OrderBooksForm-Tiers-TierColumn input {
    text-align: center !important;
    padding-left: 8px !important;
  }
  .OrderBooksForm-Tiers-MidColumn {
    text-align: right;
  }
  .TrancheOrderBook-NotesIcon {
    opacity: 1;
    &-Hidden {
      opacity: 0;
    }
    font-size: 1.8em;
    margin-left: 10px;
    .fa-stacked {
      font-size: 0.55em;
    }
  }
}

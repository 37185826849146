@import '../../main.scss';

.RoundOneSbaPppSuccessPage {
  height: 100%;
  background-color: $Color__Fog;
  .header {
    background-color: $Color__Marshmellow;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 56px;
    width: 100%;
    padding: 0 16px;
  }
  .ui.divider.LSDivider {
    border-top: none !important;
    border-bottom: 1px solid #a4b2bf !important;
  }
  .ui.divider.LSDivider + .lsHeader {
    margin-bottom: 16px;
  }
  .Tile {
    padding: 16px 24px !important;
    .ui.divider.LSDivider {
      margin: 0px 0px 16px !important;
    }
    h3 {
      margin-bottom: 16px;
      b {
        font-weight: 600;
      }
    }
    h5 {
      margin-top: 8px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      padding-inline-start: 16px;
      li {
        padding-bottom: 13px;
      }
    }
  }
  .LSTextLink.External {
    margin-top: 4px;
    .LSTextLink__Label {
      padding-left: 0px;
    }
  }
}

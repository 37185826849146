.lenderPaymentView {
  padding: 12px 16px;

  &__AmountWarning {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  &__AmountWarningText {
    margin-left: 5px;
    color: $secondaryYellow;
    font-size: 12px;
  }
}

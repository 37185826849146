@import '../../../../main.scss';

.ApplicableMarginsItem {
  display: flex;
  flex-direction: row;
  align-items: flex-end !important;
  position: relative;
  &__LevelText {
    width: 5%;
    input {
      padding: 0.67857143em 0 0.67857143em 0.5em !important;
    }
  }
  &__NameInput {
    padding: 0 0.5em !important;
  }
  &__DeleteButton {
    svg {
      color: $gray600;
      margin-bottom: 0.75em;
    }
  }
}

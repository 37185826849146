@import "../../main.scss";

.DataGridRow {
  display: table-row;
  height: 30px;
  .DataGridCell:last-child {
    border-right: none;
    vertical-align: middle;
  }
  .DataGridRow-CollapseColumn-Arrow {
    display: inline-flex;
    margin: auto;
    cursor: pointer;
    color: $primaryGreen;
    @include noSelect();
  }
}

@import '../../../main.scss';

#AlertSystem {
  position: fixed;
  top: 90px;
  transform: translateX(-50%);
  left: 50%;
  text-align: center;
  z-index: 9001;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include transition(
    '.Alert',
    (
      opacity: (
        0.1,
        1,
      ),
      transform: (
        translateY(-10px),
        translateY(0),
      ),
    ),
    150ms
  );
}

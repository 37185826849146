@import '../../../main.scss';

.LoanInformationForm {
  .Stack {
    > :not(:first-child) {
      margin-top: 16px !important;
    }
  }

  .OtherScheduleInput {
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .Principals {
    align-items: center !important;
    .readOnlyText {
      margin-right: 16px;
    }
  }
}

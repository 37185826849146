@import './../../main.scss';

.FileWrapper {
  margin-left: 5px !important;

  .label {
    margin-left: -7px;
  }

  .LSButton__Label {
    padding-bottom: 1px;
  }

  .svg-inline--fa {
    font-size: 14px;
  }
}

.ReportViewDivider {
  margin-bottom: 16px !important;
}

.Tiler .Tiler__Row .ReportViewSectionHeader {
  margin-bottom: 4px !important;
  font-size: 11px;
}

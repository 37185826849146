@import '../../../main.scss';

#Menu {
  position: relative;
  background: $darkBlue700;
  @include setFont;
  margin: 0;
  flex: 0 0 240px;
  display: flex;
  justify-content: space-between;
  padding: 80px 0 20px 0;
  border: 0;
  z-index: 100;

  .MenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    color: $grayBlue100;
    padding-left: 12px;
    border-left: 4px solid transparent;
    min-width: initial;

    &__Text {
      padding-left: 19px;
      text-align: left;
    }

    svg {
      height: 16px;
      width: auto;
    }

    &.MenuToggle {
      position: absolute;
      top: 8px;
      left: 16px;
      padding-left: 0;
      border-left: 0;
    }

    &.SignOut {
      position: absolute;
      top: 8px;
      right: 16px;
      padding-left: 0;
      border-left: 0;
      p {
        color: $grayBlue100;
        font-size: 11px;
        visibility: hidden;
        text-transform: uppercase;
        margin: 0 12px 0 0;
      }
      &:hover,
      &:focus {
        p {
          visibility: visible;
        }
      }
    }

    &.active {
      background-color: $darkBlue900;
      color: $primaryGreen400;
      border-color: $primaryGreen400;
      .MenuItem__Icon {
        color: $primaryGreen400;
      }
    }

    &--Hovered,
    &:focus {
      background-color: $darkBlue900;
    }
  }

  .MenuLogo {
    height: 20px;
    width: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.Menu--Closed {
    flex: 0 0 56px;
    .MenuItem {
      &__Text {
        position: absolute;
        background-color: $darkBlue900;
        box-shadow: 0 2px 4px 0 $darkBlue900;
        height: 40px;
        width: 184px;
        left: 37px;
        visibility: hidden;
      }
      &--Hovered {
        .MenuItem__Text {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          visibility: visible;
        }
      }
    }
  }
}
.ui.menu:after {
  display: none;
}

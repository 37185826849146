@import "../../main.scss";

.DataGridSummaryRow {
  display: table-row;
  text-align: right;
  border: none;
  .DataGridCell-DeleteColumn {
    cursor: initial !important;
    img {
      width: 20px;
      visibility: hidden;
    }
  }
}

.DataGridSummaryRow .DataGridSummaryCell:last-child {
  border-right: none;
}

@import '../../main.scss';
@import './DealGenerator/DealGenerator';

.Debug {
  display: flex;
  height: 100%;

  .DebugMenu {
    padding: 10px;
  }

  .DebugContent {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 20px;
  }

  .DebugSection {
    width: 100%;
  }
}

.loadTester {
  height: 100%;
  background: #c69;
  padding: 10px;
  &__FileInput {
    color: lime;
    margin: 5px;
  }

  &__Label {
    color: white;
    margin-top: 5px;
  }

  &__QuantityInput,
  &__Button {
    margin: 5px;
  }

  &__WoahHoldOnThere {
    margin-top: 10px;
    display: flex;
    color: white;
  }
}

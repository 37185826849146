.AbrBenchmarkOptionsFields {
  &__FormGroup {
    justify-content: space-between;
    .field.lsCheckbox {
      margin-bottom: 0.5em !important;
    }
  }
  &__Benchmarks {
    display: flex;
    flex-direction: column;
    .lsFormGroup {
      padding-left: 28px;
      > .PercentageInputFormField--WithPlus::before {
        top: 24px !important;
      }
    }
  }
  &__BenchmarkSubGroup {
    padding-left: 28px;
    padding-top: 8px;
    .Checkbox {
      padding-bottom: 0 !important;
    }
  }
  &__BenchmarkSubGroup,
  &__BenchmarkSubGroup .checkbox {
    display: flex;
    font-size: 0.8rem;
  }
}

@import '../../../main.scss';

#Main .PppForgivenessRequests {
  .lsDataTable {
    > :not(.Header):not(.Footer) {
      min-height: 180px;
    }
    > .Header,
    .Body .Row,
    .Footer {
      //                     STR, BRWR,  ID,  DRAW, ASGN, STAT, SIZE, PCT,  VIEW
      grid-template-columns: 30px 220px 100px 120px 130px 220px 125px 125px 50px;
    }
  }
}

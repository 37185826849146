.calendarEventView {
  display: flex;
  flex-direction: column;
  background: $white;
  height: 100%;
  overflow-y: hidden;
  &__DateRanges {
    height: auto;
    padding: 10px 16px 12px 16px;
    text-align: left;
  }
}
.calendarEventViewTable {
  &__Value {
    text-align: left;
    padding-right: 49px;
  }
  height: calc(100% - 82px);
}

@import '../../../main.scss';

.TopbarNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 56px;
  width: 100%;
  padding: 0 16px;
  background-color: $Color__Marshmellow;

  &__Header {
    display: flex;
    flex-direction: column;
    .DealInfo {
      display: flex;
      align-items: center;
    }
    .PppDrawTag {
      background-color: $Color__Violet !important;
      margin-left: 8px;
    }
  }
  .ActionButtons {
    display: flex;
    align-items: center;
    > * {
      margin-left: 8px;
    }
    .ActionMenu {
      .MenuIconButton {
        width: 24px;
        height: 24px;
        .LSButton__Icon {
          font-size: 23px;
        }
      }
      .Popup {
        position: absolute;
        top: 43px;
        right: 16px;
        width: 122px;
        background-color: $Color__Marshmellow;
        border: 1px solid $Color__LoanstreetGreen;
        z-index: 1000;
        .MenuItem {
          color: $Color__Steel;
          padding: 4px 8px;
          width: 100%;
          &:hover,
          &:focus {
            background-color: $Color__RobinLight;
            color: $Color__Ink;
            cursor: pointer;
          }
        }
        .ui.search.selection.dropdown.lsDropdown {
          position: fixed;
          padding: 0px !important;
          border: none !important;
          box-shadow: none !important;
          visibility: collapse;
          > input.search,
          > .default.text,
          > i.caret.icon {
            display: none !important;
          }
          > .visible.menu.transition {
            max-height: 290px !important;
            left: auto;
            margin-top: -53px;
            margin-left: -143px;
            border: 1px solid $Color__LoanstreetGreen !important;
            min-width: 140px !important;
            max-width: 140px !important;
          }
        }
      }
    }

    &__Item {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      &--ListToggle {
        margin-right: 21px;
        display: flex;
      }
      &--Upload {
        input {
          display: none;
        }
      }
    }
  }

  &__Footer {
    display: flex;
    align-items: center;

    * + .LSButton {
      margin-left: 8px;
    }
  }

  .TooltipPopup {
    // SBA-Hack: Big
    inset: 0px auto auto -220px !important;
    z-index: 1;

    .readOnlyText + .readOnlyText {
      margin-top: 8px;
    }
  }
}

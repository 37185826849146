@import '../../main.scss';

/* This selects the root element,
    in index.html, into which the 
    React App is rendered
*/
#ApplicationRoot {
  height: 100vh;
  width: 100vw;
}

.ApplicationContext {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: $Color__Fog !important;
  &__Main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .ApplicationRoutes {
      max-width: 100%;
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      height: 100%;
      min-height: 0; // firefox fix https://bugzilla.mozilla.org/show_bug.cgi?id=1043520
    }
  }
}

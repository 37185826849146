.ForgivenessTracker {
  .Tile__Header .SecondarySection {
    width: 88%;
    display: flex;
    justify-content: space-between;
    .SummaryField__Value {
      font-weight: 600;
      font-size: 14px;
    }
    .FilterSection {
      height: min-content;
      display: flex;
      > *:not(:last-child) {
        margin-right: 8px;
      }
      .LSPopup__Trigger {
        margin-left: 16px;
      }
    }
  }

  .lsDataTable {
    > .Header,
    .Body .Row,
    .Footer {
      display: grid;
      width: 100% !important;
      //                     DATE, EVNT, AMT
      grid-template-columns: 160px auto 100px;
    }

    > .Header {
      align-items: end;
      > .Cell {
        height: auto !important;
      }
    }
  }

  &__EventCell {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .TrackerDownloadLink {
      height: 18px !important;
      min-height: 18px !important;
      max-height: 18px !important;

      .LSTextLink__Label:hover,
      .LSTextLink__Label:focus {
        text-decoration: none;
      }
    }
  }

  .NoForgiveness > .Tile__Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 55px 0px 99px;
    .Space-1 {
      height: 4px;
    }
    .Space-5_5 {
      height: 22px;
    }
  }
}

.FilterPopup.CheckboxGroup {
  width: 255px;
}

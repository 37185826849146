.feePrepayment {
  &__AddRangeBtn {
    margin-top: 18px;
    margin-bottom: 18px;
  }
}

.feePrepaymentDisplay {
  text-align: right;
}

.feePrepaymentItem {
  display: flex;
  align-items: center;
  width: 100%;
  &__Fields {
    flex-grow: 1;
  }
  &__DeleteIcon {
    margin-left: 8px;
    margin-bottom: 7px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--WithLabel {
      margin-top: 22px;
    }
  }
}

$maxWidth: 200px;

#FileRowRenameInput {
  display: inline-block;
  overflow-y: initial;
  max-width: $maxWidth;
  .formContent {
    max-width: $maxWidth;
    padding: 0;
    .input {
      input {
        // padding: 3px;
        // border: none;
        // background-color: $white !important;
        // overflow: hidden;
        height: 20px;
        max-width: $maxWidth;
      }
    }
  }
  /* stylelint-disable */
  .formFooter,
  label {
    display: none;
  }
}

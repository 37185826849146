@import '../../../main.scss';
@import './dataroomRowMenu/DataroomRowMenu';

.dataroomRow {
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  grid-template-rows: 100%;

  &--IsOver {
    background: $gray400 !important;
    border: 2px $primaryGreen solid;
  }

  &--DenyDrop {
    opacity: 0.3;
  }

  &__NameCell {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
  }

  &__DragPreview {
    max-width: 100%;
    height: 30px;
    display: inline-flex;
    align-items: center;
    min-width: 160px;
    padding: 0px 5px;
    width: auto !important;
  }

  &--Dragging {
    cursor: grabbing !important;
    opacity: 0.3;
  }

  &__DropArea {
    cursor: grabbing;
    background: $gray400 !important;
    border-left: 2px solid $primaryGreen;
    border-right: 2px solid $primaryGreen;

    &--Disabled {
      opacity: 0.3;
    }
    &--First {
      border-top: 2px solid $primaryGreen;
    }
    &--Last {
      border-bottom: 2px solid $primaryGreen;
    }
  }

  &--Even {
    background: $gray200;
  }
  &:hover,
  &:focus {
    background: $green100;
  }

  &__AccessCell {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 100%;
    margin-right: 30px;
  }

  &__ModifiedCell {
    margin-left: 12px;
    align-self: center;
  }
}

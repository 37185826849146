@import '../../../main.scss';

.RoundTwoSupportDocsWrapper {
  padding: 24px;
  background-color: $Color__Marshmellow;
  overflow: auto;
  height: 100%;
  .SupportDocs {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    h1 {
      margin-bottom: 12px;
    }
    .divider {
      margin: 0px;
    }
    .readOnlyText {
      padding: 8px 0px !important;
    }
    h4 {
      margin-top: 12px;
    }
    h4,
    .field {
      margin-bottom: 12px;
    }
    li:not(:last-of-type) {
      margin-bottom: 12px;
    }

    .textLine {
      .LSTextLink {
        display: inline;
      }
      .LSTextLink .LSTextLink__Label {
        padding: 0px;
      }
    }
  }
}

@import "../../main.scss";

.DataGridHeaderRow {
  display: table-row;

  &:not(.DataGridHeaderRow-Borderless) {
    border-bottom: 2px solid $gray300;
  }
  .DataGridCell-DeleteColumn {
    cursor: initial !important;
    a,
    button {
      cursor: initial !important;
      .lsSVG {
        visibility: hidden;
      }
    }
  }
}

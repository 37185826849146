$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$widescreenMonitorBreakpoint: 1920px;
$displayTableCellPadding: 3px 5px 1px 13px;

/** Design's Typography System. Please add as needed:
https://app.zeplin.io/project/5e2f34632b5bdc7f2037f1af/screen/5e2f5a8111211a5362ca3dcf
*/
$FontStyle: 'IBM Plex Sans', serif;

$FontWeight__Normal: 400;
$FontSize__H3: 14px;
$FontSize__ParagraphLarge: 13px;
$FontSize__ParagraphSmall: 12px;
$FontSize__TableHeading: 11px;
$FontSize__HelperText: 9px;

$FontWeight__Normal: 400;
$FontWeight__Bold: 600;

.RoundTwoQuestions {
  .field {
    label.Radio.Radio__Container {
      padding-bottom: 0px;
    }
  }

  .steel.help-text {
    font-style: italic;
  }
}

.lenderRegistryInstitutionRow {
  background: $green50;
  @include lenderRegistryGridRow();

  &--Collapsed {
    border-bottom: 1px solid $gray300;
  }

  &__Icon {
    margin-right: 8px;
  }

  &__NameColumn {
    display: flex;
    align-items: center;
    &Text {
      color: $primaryGreen;
    }
  }

  &__IconColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__InputColumn {
    width: 100%;
    justify-self: end;
    align-self: auto !important;
    padding: 0rem 0.5rem;
    &--Bold {
      > div > input {
        font-weight: bold;
      }
    }
    margin: 0px 0px 0px 8px !important;

    &.disabled {
      > div > input {
        text-align: right;
      }
    }
  }
}

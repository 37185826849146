@import '../../../main.scss';

.dataroomPdfViewer {
  overflow-y: auto;
  height: 100%;
  width: 100%;

  &__Header {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    align-items: center;
  }

  &__IconGroup {
    display: flex;
  }

  &__Icon {
    margin-right: 4px;
    &--Download {
      color: $white;
      &:focus,
      &:hover {
        color: $white;
      }
    }
  }
}

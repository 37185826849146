@import '../../main.scss';

.TermSheet__Dropzone {
  margin-top: 50px;
  width: 50%;
  min-width: 400px;
  max-width: 550px;
}

#TermSheet {
  position: relative;
  width: 100%;
  height: 100%;
  align-self: stretch;
  display: flex;

  .TermSheet-PDF {
    position: relative;
    width: 100%;
    height: 100%;
    align-self: stretch;
    display: flex;
  }
}
/*
#TermSheet {
  position: relative;
  width: 100%;
  align-self: stretch;
  display: flex;
  height: 100%;
  max-height: 100%;
  
}
*/

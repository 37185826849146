@import '../../../main.scss';

.BorrowerDeals-MainSection .AllLoans {
  .lsDataTable {
    > .Header,
    .Body .Row,
    .Footer {
      //                     CRTD, LNDR,  ID,  DRAW,  NUM, STG, STAT, SIZE
      grid-template-columns: 150px 215px 110px 125px 145px 80px 170px 125px;
    }
  }

  .lsDataTableWrapper .lsDataTable .Row.Header {
    min-height: auto;
  }
}

@import '../../../main.scss';

#InstitutionProfileView {
  .Overview {
    &__InstitutionName {
      font-weight: bold;
    }
  }
  .BankAccount {
    &__Header {
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      .AccountNickname {
        display: inline-block;
        margin: 0 16px 0 0;
        font-weight: bold;
      }
    }
    &__AccountInfo {
      display: flex;
      > div {
        flex: 1;
      }
    }
  }

  .lsHeader {
    margin-bottom: 16px;
  }
  .lsFormGroup {
    .FormReadOnly {
      margin-right: 24px;
    }
  }
}

.PrimaryAccountIndicator {
  background-color: $primaryGreen400;
  border-radius: 8px;
  font-size: 10px;
  display: inline-block;
  p {
    display: flex;
    align-items: center;
    height: 16px;
    padding: 0 12px;
  }
}

@import '../../../main.scss';
.EscrowReserve {
  align-items: flex-end !important;
  .EscrowFields-DeleteButton {
    margin-bottom: 0.8em;
    svg {
      color: $gray600;
      margin: 4px 0 0 14px;
    }
  }
}

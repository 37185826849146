.CalendarTaskView {
  height: 100%;
  display: flex;
  overflow-y: auto;
  padding: 16px;

  .Header__ActionButtons {
    display: flex;
    align-items: center;

    .Icon {
      margin-right: 2px !important;
    }
    button {
      margin-left: 6px !important;
    }
  }
}

.ServicingAgentInvitationRow {
  .lsFormGroup:not(.lsFormGroup--Lowered) {
    margin-bottom: 0 !important;
    padding: 3% 0;
  }
  .accessLevels {
    padding: 0 0.5em;
  }
  &.isHeader {
    .DeleteButton {
      margin-top: calc(1rem + 4px);
    }
  }
}

@import '../../main.scss';

#Main {
  width: 100%;
  display: flex;
  flex-direction: column;
  .TabNavigationMenu {
    margin: 16px 32px 0;
    min-height: 22px;
  }
  background-color: $Color__Fog;
  height: 100vh;
  color: $Color__Ink;
  /* stylelint-disable */
  & > .Deals-MainSection {
    width: 100%;
    & > .Tiler > .Tile {
      /* stylelint-enable */
      padding-bottom: 8px;
      > .Tile__Header {
        margin-bottom: 0px;
        height: 24px;
      }
      .Filters {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: -20px;

        .Filter {
          margin: 4px 8px 0 0;
          .Filter__Popup {
            width: 180px;
          }
        }
        .lsInput {
          padding: 4px 6px;
        }
      }
      .Searchbox .Icon {
        font-size: 12px;
      }
      .DownloadButton {
        color: $Color__LoanstreetGreen;
        margin-left: 34px;
      }
      .lsDataTable {
        > :not(.Header):not(.Footer) {
          min-height: 180px;
        }
        .favoriteStar {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          border-bottom: 1px solid #f9f9f9;
          > svg {
            width: 11px;
            color: $Color__Ink;
          }
        }
        > .Header,
        .Body .Row,
        .Footer {
          display: grid;
          width: 100% !important;
        }
        .Body .Row:not(:hover) > .Cell.view,
        .Body .Row:not(:focus) > .Cell.view {
          color: transparent;
        }
        .Body .Row:hover > .Cell.view,
        .Body .Row:focus > .Cell.view {
          color: $Color__LoanstreetGreen;
        }
        .Header .favoriteStar {
          margin-right: 1px;
          > svg {
            color: $Color__Steel;
          }
        }
        > .Header {
          align-items: end;
          > .Cell {
            height: auto !important;
          }
        }
      }
      .totalLoans {
        margin-top: -18px; // align with the DataTable Footer
        font-size: $FontSize__ParagraphLarge;
      }
    }
  }
}

@import '../../../DealClosingWorkflow.scss';
.LenderApplicationQuestions {
  .section {
    .field .ui.text .readOnlyText {
      margin-bottom: 4px !important;
    }
    .field {
      margin: 15px 0 0 0 !important;
      margin-top: 7px !important;
    }
    .FormField__SmallText--Help {
      margin-top: 0px !important;
    }
  }
}

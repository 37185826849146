.folderShareModal {
  width: 600px !important;
  min-height: 244px;

  &--institutionList {
    padding: 8px;
  }

  &__ModalFooter {
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }

  &--DeleteButton:not(.disabled) {
    padding-left: 10px !important;
    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}
